import styled from "styled-components"
import About from "../components/About";
import Services from "../components/Services";
import Contact from "../components/Contact";
import Description from "../components/Description";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 71.875rem;
  margin-top: 2rem;
`;

const Home = () => {
  return (
    <Container>
      <About />
      <Services />
      <Description />
      <Contact />
    </Container>
  )
}

export default Home
