import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

const Left = styled.div``;
const Center = styled.div``;
const Right = styled.div``;

const Link = styled.a`
  color: var(--color-links);
  font-size: 1.125rem;
  font-weight: 600;
  padding: 1rem;
`;

const SectionMark = styled.div`
  height: .0625rem;
  width: 100%;
  background-color: var(--color-border);
  margin-bottom: 0.5rem;
`;

const Footer = () => {
  return (
    <Container id="footer">
      <SectionMark />
      <SubContainer>
        <Left></Left>
        <Center></Center>
        <Right>
          <Link href="/Secure">Datenschutz</Link>
          <Link href="/AGB">AGB's</Link>
          <Link href="/Imprint">Impressum</Link>
        </Right>
      </SubContainer>
    </Container >
  )
}

export default Footer
