import styled from "styled-components"
import image from '../assets/description.png'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  border: solid .0625rem var(--color-border);
`;

const Header = styled.h1`
  margin: 2rem 0 2rem 0;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: .125rem;
`;

const Text = styled.p`
  text-align: center;
  margin: 0 6rem 0 6rem ;

  @media screen and (max-width: 62.5rem) {
    margin: 0 1rem 0 1rem ;
  }

`;

const Link = styled.a`
  margin-top: 1rem;
  color: red;
  font-size: 1rem;
  word-break: break-all;
  margin: 1rem 2rem 0 2rem ;
  text-align: center;

  transition: 0.2s linear;

  &:hover{
    transform: translateY(-.125rem);
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  width: auto;
  margin-bottom: 1rem;
`;

const Description = () => {
  return (
    <Container id="description">
      <Header id="header">Warum einen Ziviltechniker beauftragen?</Header>
      <Text>
        Ziviltechniker sind mit öffentlichem Glauben versehene Personen. Sie sind ermächtigt, öffentliche Urkunden auszustellen, welche von Verwaltungsbehörden auf gleiche Weise wie selbst erstellte Urkunden angesehen werden.
        Zur Erlangung der Befugnis sind ein Hochschulstudium mit Masterabschluss, jahrelange Berufserfahrung und die Ablegung einer kommissionellen Prüfung erforderlich.
        Im Rahmen der Befugnis ist der Ziviltechniker zu beratenden, planenden oder gutachterlichen Tätigkeiten ermächtigt, nicht jedoch zu ausführenden Tätigkeiten wie beispielsweise dem Bau einer Maschine.
        Der Ziviltechniker ist unter völliger Verschwiegenheit stets zur Wahrung der Auftraggeberinteressen verpflichet.
        [Quelle: ZTG 2019]
        Weitere Informationen bezüglich des Befugnisumfangs und der Stärken der Ziviltechniker finden Sie unter folgendem Link: <br />
      </Text>

      <Link target="_blank" href="https://www.arching.at/ziviltechnikerinnen/die_ziviltechnikerinnen.html">https://www.arching.at/ziviltechnikerinnen/die_ziviltechnikerinnen.html</Link>
      <Image src={image} />


    </Container >
  )
}

export default Description