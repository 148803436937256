import styled from "styled-components";
import gearImage from '../assets/gear.png';
import imgAnja from '../assets/1000005247.png';
import imgAndi from '../assets/1000005246.png';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  `;

const HeadImage = styled.img`
  border: solid .0625rem var(--color-border);
  width: 100%;
  `;

const SectionMark = styled.div`
  height: .0625rem;
  width: 80%;
  background-color: var(--color-border);
`;

const AboutContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: solid .0625rem var(--color-border);
  margin: 1.875rem 0 1.875rem 0;  
`;

const AboutSubContainer = styled.div`
  display: flex;
  align-items: center;
  
  @media screen and (max-width: 62.5rem) {
    flex-direction: column;
    margin-top: 3rem;
    
    &:last-of-type{
      flex-direction: column-reverse; 
    }
  }
`;

const AboutHeader = styled.h1`
  margin-top: 2rem;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: .125rem;
`;

const AboutImage = styled.img`
  max-width: 100%;
  height: auto;
  width: auto;
  max-height: 37.5rem;

  transform: scaleX(-1);

  @media screen and (max-width: 62.5rem) {
    max-height: 20rem;
  }
`;

const AboutTextContainer = styled.div`
  display: flex;
  flex-direction:column;
  align-items: center;
`;

const AboutTextHeader = styled.h3`
  margin-bottom: 2rem;
  text-decoration: underline;
  @media screen and (max-width: 62.5rem) {
    margin-bottom: 0.5rem;    
  }

`;

const AboutText = styled.p`
  margin: 1rem 2rem 0 2rem;
  text-align: center;

  @media screen and (max-width: 62.5rem) {
    margin: 1rem 1rem 0 1rem ;
  }

`;

const About = () => {
  return (
    <Container>
      <HeadImage src={gearImage} />
      <AboutContainer id="about">
        <AboutHeader id="header">Über uns</AboutHeader>
        <AboutSubContainer>
          <AboutTextContainer>
            <AboutTextHeader>DI (FH) Anja Hajek</AboutTextHeader>
            <AboutText>Nach meiner Ausbildung zur KFZ-Mechatronikerin beendete ich 2010 mein Diplom-Studium der Fahrzeugtechnik an der Fachhochschule München.
              Meine Diplomarbeit verfasste ich in Zusammenarbeit mit dem LKR LEICHTMETALLKOMPETENZZENTRUM in Ranshofen.
            </AboutText>
            <AboutText>Das Leben und die Liebe brachten mich im Anschluss nach Österreich, wo ich meine Erstanstellung als Berechnungsingenieurin und Konstrukteurin bei der WINTERSTEIGER AG in Ried im Innkreis antrat.
              Im Anschluss wechselte ich zur AMAG ROLLING GMBH in Ranshofen, startete dort meine Laufbahn als Prozesstechnikerin und
              entwickelte mich weiter zur Produktionsleiterin im Bereich Aluminium-Platten-Fertigung.
            </AboutText>
            <AboutText>
              Nebenberuflich lehrte ich drei Jahre am BFI Braunau für die Werkmeister-Ausbildung die Fächer „Technische Mechanik“ und „Fertigungstechnik“.
            </AboutText>
            <AboutText>
              Mit der Vereidigung zur Ziviltechnikerin für Maschinenbau und Fahrzeugtechnik durch Landeshauptmann Mag. Thomas STELZER,
              erfolgte am 13.03.2024 der Schritt in die Selbstständigkeit.
            </AboutText>
          </AboutTextContainer>
          <AboutImage style={{marginBottom: '2rem', marginRight: '2rem'}} src={imgAnja} />
        </AboutSubContainer>
        <SectionMark />
        <AboutSubContainer>
          <AboutImage style={{marginBottom: '2rem', marginLeft: '2rem'}} src={imgAndi} />
          <AboutTextContainer>
            <AboutTextHeader>DI (FH) Andreas Hajek</AboutTextHeader>
            <AboutText>
              2009 beendete ich mein Diplom-Studium des allgemeinen Maschinenbaus an der Fachhochschule München.
            </AboutText>
            <AboutText>
              Meine Diplomarbeit verfasste ich in Zusammenarbeit mit AMAG ROLLING GmbH und durfte im Anschluss eine Festanstellung als Werkstofftechnologe antreten.
              In den folgenden Jahren nahm ich 5 Produktionsanlagen in Betrieb und entwickelte ich mich zum Produktionsleiter im Bereich Finalisierung und Schneidanlagen.
              Ein Jobwechsel zur B-SOLUTION GmbH in Hallein brachte mich dem Werkstoff Holz näher. Seit 2023 bin ich als Werksleiter der WIESNER-HAGER GmbH in Altheim angestellt.
            </AboutText>
            <AboutText>
              Nebenberuflich lehrte ich vier Jahre am BFI Braunau für die Werkmeister-Ausbildung die Fächer „Technische Mechanik“ und „Fertigungstechnik“.
            </AboutText>
            <AboutText>
              Am 28.02.2022 wurde ich von Landeshauptmann Mag. Thomas STELZER zum Ziviltechniker für Maschinenbau vereidigt.
              Meine Befugnis ist jedoch als ruhend anzusehen.
            </AboutText>
            <AboutText>
              Ich unterstütze meine Frau in Hinblick auf die §82 Überprüfungen und stehe auch bei Fragen zu CE-Kennzeichnung
              und rechtskonformen Betriebsstrukturen mit Rat und Tat zur Seite.
            </AboutText>
          </AboutTextContainer>
        </AboutSubContainer>
      </AboutContainer>
    </Container>
  )
}

export default About
