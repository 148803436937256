import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 71.875rem;
  margin-top: 2rem;
`;

const TextSubContainer = styled.div`
  border: solid 0.0625rem var(--color-border);
`;

const Header = styled.h1`
  @media screen and (max-width: 26.875rem) {
    word-break: break-all;
  }
`;

const Date = styled.p`
  align-self: end;
  margin: 0.5rem 0 0.5rem 0;
`;

const ListItemContainer = styled.div`
  margin: 2rem 1rem 1rem 1rem;
`;

const ListHeaderContainer = styled.h3`
  display: flex;
`;
const ListHeaderNumber = styled.h3`
  min-width: 1.5rem;
  text-align: left;
`;
const ListHeaderText = styled.h3`
  margin-left: 1rem;
`;

const ListTextContainer = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const UList = styled.ul`
  margin: 0.5rem 0 0 2rem;
`;
const UListItem = styled.li``;
const ListTextNumber = styled.p`
  min-width: 1.5rem;
  text-align: left;
`;
const ListText = styled.p`
  text-align: justify;
  margin-left: 2rem;

  @media screen and (max-width: 26.875rem) {
    margin-left: 1rem;
  }
`;

const AGB = () => {
  return (
    <Container>
      <Header id="header">
        Allgemeine Geschäftsbedingungen für Ziviltechnikerdienstleistungen
      </Header>
      <Date>Stand: März 2024</Date>

      <TextSubContainer>
        {/* 1. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>1.</ListHeaderNumber>
            <ListHeaderText>Geltung</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>1.1</ListTextNumber>
            <ListText>
              Die Leistungen und Angebote sowie alle mit dem Auftraggeber/der
              Auftraggeberin (AG) abgeschlossenen Verträge des
              Ziviltechnikerbüros (der Ziviltechnikergesellschaft) erfolgen
              ausschließlich aufgrund dieser AGB-ZT und zwar unabhängig von der
              Art des Rechtsgeschäftes. Entgegenstehende oder von diesen AGB-ZT
              abweichende Bedingungen der/des AG sind nicht anzuwenden, wenn
              ihrer Geltung nicht schriftlich und ausdrücklich zugestimmt wurde.
              Vertragserfüllungshandlungen unsererseits gelten nicht als
              Zustimmung zu von unseren AGB-ZT abweichenden Vertragsbedingungen.
              Diese AGB-ZT gelten als Rahmenvereinbarung auch für alle weiteren
              Rechtsgeschäfte zwischen den Vertragspartnern.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 2. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>2.</ListHeaderNumber>
            <ListHeaderText>Vertragsabschluss</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>2.1</ListTextNumber>
            <ListText>
              Die Honorarangebote der Auftragnehmerin verstehen sich
              unverbindlich und freibleibend. Von diesen AGB-ZT oder anderen
              schriftlichen Willenserklärungen abweichende mündliche Zusagen,
              Nebenabreden u. dgl., insbesondere solche, die von
              Dienstnehmerinnen/Dienstnehmern, Zustellern/-innen etc. abgegeben
              werden, sind nicht verbindlich.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>2.2</ListTextNumber>
            <ListText>
              Enthält die Auftragsbestätigung Änderungen gegenüber dem Auftrag,
              so gelten diese als vom Vertragspartner/von der Vertragspartnerin
              genehmigt, sofern dieser nicht unverzüglich widerspricht. Werden
              an uns Angebote gerichtet, so ist der/die Anbietende eine
              angemessene, mindestens jedoch achttägige Frist ab Zugang des
              Angebotes daran gebunden.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>2.3</ListTextNumber>
            <ListText>
              Der Inhalt des mit dem Vertragspartner/der Vertragspartnerin
              abgeschlossenen Vertrages ergibt sich primär aus dem schriftlichen
              Vertrag samt Anlagen, der Vollmacht und diesen AGB-ZT. Der Pkt
              2.1.) 1. und 2. Satz und 2.2) gilt nicht bei
              Verbrauchergeschäften.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 3. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>3.</ListHeaderNumber>
            <ListHeaderText>Honorar</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>3.1</ListTextNumber>
            <ListText>
              Unsere Leistungen werden auf Basis des für das Fachgebiet jeweils
              zutreffenden Leistungsziels, des Leistungsumfangs, der
              Leistungszeit sowie der Umstände der Leistungserbringung bemessen.
              Ändern sich die Parameter für die Kalkulation während der
              Bearbeitungszeit, so werden die danach erbrachten Leistungen auf
              Grundlage der neuen Parameter verrechnet.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>3.2</ListTextNumber>
            <ListText>
              Sollten sich die Lohnkosten zwischen Vertragsabschluss und
              Leistungserbringung aufgrund kollektivvertraglicher Regelungen in
              der Branche oder innerbetrieblicher Abschlüsse oder sollten sich
              andere, für die Kalkulation relevante Kostenstellen oder zur
              Leistungserstellung notwendige Kosten, wie jene für Materialien,
              Energie, Transporte, Fremdarbeiten, Finanzierung etc. verändern,
              so sind wir berechtigt, die Preise entsprechend zu erhöhen oder zu
              ermäßigen. Pkt 3.2. gilt nicht bei Verbrauchergeschäften.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>3.3</ListTextNumber>
            <ListText>
              Die Nebenkosten (Wege-/Fahrtkosten innerhalb des Ortes des
              Bürositzes, Arbeitskopien und interne Kopien aller Art, Kopien für
              die an den Planungsleistungen Beteiligten, erforderliche
              Unterlagen für den AG-seitigen internen Gebrauch in ausreichender
              Anzahl) werden mit einem Nebenkostenpauschale gemäß Honorarangebot
              vergütet. Zusätzlich werden folgende Nebenkosten nach dem
              tatsächlichen Aufwand verrechnet:
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>3.4</ListTextNumber>
            <ListText>
              Die Nebenkosten (Wege-/Fahrtkosten innerhalb des Ortes des
              Bürositzes, Arbeitskopien und inter-ne Kopien aller Art, Kopien
              für die an den Planungsleistungen Beteiligten, erforderliche
              Unterlagen für den AG-seitigen internen Gebrauch in ausreichender
              Anzahl) werden mit einem Nebenkosten-pauschale gemäß
              Honorarangebot vergütet. Zusätzlich werden folgende Nebenkosten
              nach dem tatsächlichen Aufwand verrechnet:
              <UList>
                <UListItem>
                  behördliche Kommissionsgebühren, Stempel- und Rechtsgebühren,
                  Verwaltungsabgaben, Gerichtskosten u. dgl.;
                </UListItem>
                <UListItem>
                  Kopien und Vervielfältigungen von Dokumenten und Plänen, die
                  über die vom Nebenkostenpauschale umfassenden Ausfertigungen
                  hinausgehen;
                </UListItem>
                <UListItem>Reisekosten außerhalb des Bürositzes;</UListItem>
              </UList>
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 4. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>4.</ListHeaderNumber>
            <ListHeaderText>Zahlungsbedingungen, Verzugszinsen</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>4.1</ListTextNumber>
            <ListText>
              Wir ist berechtigt, Teilrechnungen, die die Umsatzsteuer in der
              gesetzlichen Höhe enthalten können, zu legen.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>4.2</ListTextNumber>
            <ListText>
              Teilrechnungen werden innerhalb von 14 Kalendertagen, die
              Schlussrechnung innerhalb von 30 Kalendertagen jeweils ab
              Rechnungseingang beim AG fällig. Ohne besondere Vereinbarung ist
              der Abzug eines Skontos nicht zulässig.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>4.3</ListTextNumber>
            <ListText>
              Bei Zahlungsverzug sind wir berechtigt, Verzugszinsen in
              gesetzlicher Höhe zu verrechnen.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 5. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>5.</ListHeaderNumber>
            <ListHeaderText>Rücktritt vom Vertrag</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>5.1</ListTextNumber>
            <ListText>
              Für den Fall des Rücktrittes gelten die Bestimmungen des ABGB.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>5.2</ListTextNumber>
            <ListText>
              Neben den allgemeinen gesetzlichen Gründen sind wir auch bei
              Annahmeverzug oder anderen wichtigen Gründen, wie insbesondere bei
              Unterbrechung der Leistung für mehr als drei Monate durch den/die
              AG und bei Vereitlung der Leistung durch den/die AG, zum Rücktritt
              vom Vertrag berechtigt.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>5.3</ListTextNumber>
            <ListText>
              Bei Zahlungsverzug des Vertragspartners/der Vertragspartnerin sind
              wir von allen weiteren Leistungs- und Lieferungsverpflichtungen
              entbunden und berechtigt, noch ausstehende Lieferungen oder
              Leistungen zurückzuhalten und Vorauszahlungen bzw.
              Sicherstellungen zu fordern oder – gegebenenfalls nach Setzung
              einer angemessenen Nachfrist – vom Vertrag zurückzutreten.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>5.4</ListTextNumber>
            <ListText>
              Tritt der Vertragspartner/die Vertragspartnerin – ohne dazu
              berechtigt zu sein – vom Vertrag zurück oder begehrt er/sie
              unberechtigt seine Aufhebung, so haben wir die Wahl, auf der
              Erfüllung der Vertrages zu bestehen oder der Aufhebung des
              Vertrages zuzustimmen; im letzten Fall gilt Punkt 5.1.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>5.5</ListTextNumber>
            <ListText>
              Für den Fall des berechtigten Rücktrittes unserer
              Vertragspartner/-innen steht uns nur das Entgelt für die
              Leistungen bis zur Wirksamkeit des Rücktrittes zu.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>5.6</ListTextNumber>
            <ListText>
              Der Rücktritt ist schriftlich mittels eingeschriebenen Briefes zu
              erklären.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 6. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>6.</ListHeaderNumber>
            <ListHeaderText>Mahn- und Inkassospesen</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>6.1</ListTextNumber>
            <ListText>
              Im Falle des Zahlungsverzuges hat der AG entstehende Mahnspesen in
              Höhe von pauschal € 15,00 zuzüglich Porto pro erfolgter Mahnung
              sowie für die Evidenzhaltung des Schuldverhältnisses im Mahnwesen
              pro Halbjahr einen Betrag von € 5,00 zu ersetzen. Darüber hinaus
              sind alle Kosten und Spesen, die aus der Mahnung oder dem Inkasso
              fälliger Zahlungen entstehen, insbesondere die zur
              zweckentsprechenden Rechtsverfolgung notwendigen und tarifmäßigen
              außergerichtlichen Anwaltskosten etc., vom Schuldner/von der
              Schuldnerin zu ersetzen.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 7. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>7.</ListHeaderNumber>
            <ListHeaderText>Eigentumsvorbehalt</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>7.1</ListTextNumber>
            <ListText>
              Alle Sachen und Unterlagen (Pläne, Berechnungen etc) werden von
              uns unter Eigentumsvorbehalt übergeben und bleiben bis zur
              vollständigen Bezahlung unser Eigentum. Im Verzugsfall sind wir
              jederzeit zur Zurücknahme berechtigt.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>7.2</ListTextNumber>
            <ListText>
              Bei Zurückforderung bzw. Zurücknahme der unter Eigentumsvorbehalt
              stehenden Sache durch uns liegt nur dann ein Rücktritt vom Vertrag
              vor, wenn dieser ausdrücklich erklärt wird.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>7.3</ListTextNumber>
            <ListText>
              Der/die AG trägt das volle Risiko für die Vorbehaltssache,
              insbesondere für die Gefahr des Unterganges, des Verlustes oder
              der Verschlechterung.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 8. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>8.</ListHeaderNumber>
            <ListHeaderText>Aufrechnungsverbot</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>8.1</ListTextNumber>
            <ListText>
              Die Kompensation allfälliger Gegenforderungen mit unserer
              (Honorar)forderung, aus welchem Grund auch immer, ist unzulässig.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>8.2</ListTextNumber>
            <ListText>
              Forderungen gegen uns dürfen ohne unsere ausdrückliche Zustimmung
              nicht abgetreten werden. Pkt 8.1.) gilt nicht bei
              Verbrauchergeschäften.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 9. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>9.</ListHeaderNumber>
            <ListHeaderText>Urheberrecht</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>9.1</ListTextNumber>
            <ListText>
              Unabhängig davon, ob das von uns hergestellte Werk (z.B. Pläne,
              Skizzen, Modelle, sonstige Dokumentationen und Schriftstücke)
              urheberrechtlich geschützt ist oder nicht, erhält der
              Auftraggeber/die Auftraggeberin das Recht, das Werk zum
              vertraglich bedungenen Zweck zu benutzen, nur unter der Bedingung
              der vollständigen Vertragserfüllung.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>9.2</ListTextNumber>
            <ListText>
              Der Auftragnehmer/die Auftragnehmerin hat das Recht, von ihm/ihr
              im Zuge der Auftragsabwicklung (auch in digitaler Form) erhobene
              Daten und Informationen ohne Einschränkung zu benützen. Sie können
              insbesondere auch zur Erfüllung eines neuen Auftrages verwendet
              werden.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 10. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>10.</ListHeaderNumber>
            <ListHeaderText>
              Verwahrung bzw. Herausgabe der Unterlagen
            </ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>10.1</ListTextNumber>
            <ListText>
              Die Originalpläne, Originalzeichnungen und -daten, sowie
              Schriftstücke verbleiben bei uns verwahrt, wobei wir uns dafür
              auch des elektronischen Urkundenarchivs der ZT bedienen können.
              Wir sind verpflichtet, unserem Vertragspartner/unserer
              Vertragspartnerin auf dessen/deren Verlangen Vervielfältigungen
              dieser Unterlagen in Papierform gegen Kostenersatz auszuhändigen.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>10.2</ListTextNumber>
            <ListText>
              Wird die Herausgabe von Unterlagen in digitaler Form vereinbart,
              trifft uns keine wie immer geartete Haftung. Der Auftraggeber/die
              Auftraggeberin hat uns diesbezüglich schad- und klaglos zu halten.
              Wir übernehmen keine Haftung für Fehler oder Schäden, die auf der
              EDV-Anlage des Empfängers der digitalen Daten entstehen könnten.
              Wir setzen EDV-Programme zur Vermeidung aggressiver EDV-Programme
              (Viren, Würmer, etc.) ein.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>10.3</ListTextNumber>
            <ListText>
              Unsere Aufbewahrungspflicht endet zehn Jahre nach Legung der
              Schlusshonorarnote an den/die AG. Wir können uns während dieser
              Zeit durch Herausgabe der Originalunterlagen an den
              Vertragspartner/die Vertragspartnerin von unserer
              Verwahrungspflicht befreien.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>10.4</ListTextNumber>
            <ListText>
              Die Aufbewahrungspflicht für Urkunden entspricht der gesetzlichen
              Regelung nach ZTG 2019 und hat über 30 Jahre (in digitaler Form)
              zu erfolgen.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 11. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>11.</ListHeaderNumber>
            <ListHeaderText>Zurückbehaltung</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>11.1</ListTextNumber>
            <ListText>
              Der Vertragspartner/die Vertragspartnerin ist bei gerechtfertigter
              Reklamation außer in den Fällen der Rückabwicklung nicht zur
              Zurückhaltung des gesamten, sondern nur bis zu einem den
              voraussichtlichen Behebungsaufwand bzw. Schaden entsprechenden
              Teiles des Bruttohonorarbetrages berechtigt. Pkt XI gilt nicht bei
              Verbrauchergeschäften.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 12. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>12.</ListHeaderNumber>
            <ListHeaderText>
              Verwahrung bzw. Herausgabe der Unterlagen
            </ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>12.1</ListTextNumber>
            <ListText>
              Soweit der Vertragspartner seine Zahlungsverpflichtung in
              Teilbeträgen abzustatten hat, gilt als vereinbart, dass bei nicht
              fristgerechter Bezahlung auch nur einer Rate sämtliche noch
              ausständigen Teilleistungen ohne weitere Nachfristsetzung sofort
              fällig werden.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>12.2</ListTextNumber>
            <ListText>
              Pkt 12 gilt bei Verbrauchergeschäften, soweit wir unsere Leistung
              vollständig erbracht haben, auch nur eine rückständige
              Teilleistung des/der AG mindestens sechs Wochen fällig ist, und
              wenn wir den/die AG unter Setzung einer Nachfrist von zumindest
              zwei Wochen unter Androhung des Terminverlustes gemahnt haben.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 13. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>13.</ListHeaderNumber>
            <ListHeaderText>
              Gewährleistung, Untersuchung und Rügepflicht
            </ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>13.1</ListTextNumber>
            <ListText>
              Gewährleistungsansprüche des Vertragspartners/der
              Vertragspartnerin erfüllen wir bei Vorliegen eines behebbaren
              Mangels nach unserer Wahl entweder durch Austausch, Reparatur
              innerhalb angemessener Frist oder Preisminderung.
              Schadenersatzansprüche des/der AG, die auf Behebung des Mangels
              zielen, können erst geltend gemacht werden, wenn wir mit der
              Erfüllung der Gewährleistungsansprüche in Verzug geraten sind.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>13.2</ListTextNumber>
            <ListText>
              Der Vertragspartner/die Vertragspartnerin hat uns Mängel, die
              nicht bereits bei der Übernahme schriftlich beanstandet wurden,
              unverzüglich, längstens aber binnen Wochenfrist nach ihrer
              Entdeckung schriftlich zu rügen. Wird eine Mängelrüge nicht oder
              nicht rechtzeitig erhoben, so gilt unsere Leistung als genehmigt.
              Die Punkte 13.1.) und 13.2.) gelten nicht bei
              Verbrauchergeschäften.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>13.3</ListTextNumber>
            <ListText>
              Die Gewährleistungsfrist für von uns erbrachte Leistungen beträgt
              drei Jahre bei unbeweglichen Sachen und zwei Jahre bei beweglichen
              Sachen, ab Abschluss der vertraglich vereinbarten Gesamtleistung.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>13.4</ListTextNumber>
            <ListText>
              Bei Verbrauchergeschäften können wir uns bei einer Gattungsschuld
              von den Ansprüchen des/der AG auf Aufhebung des Vertrages oder auf
              angemessene Preisminderung dadurch befreien, dass wir in
              angemessener Frist die mangelhafte Sache gegen eine mängelfreie
              austauschen. Wir können von der Pflicht zur Gewährung einer
              angemessenen Preisminderung dadurch befreien, dass wir in
              angemessener Frist in einer für den Verbraucher/die Verbraucherin
              zumutbaren Weise eine Verbesserung bewirken oder das Fehlende
              nachtragen.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 14. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>14.</ListHeaderNumber>
            <ListHeaderText>Schadenersatz</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>14.1</ListTextNumber>
            <ListText>
              Sämtliche Schadenersatzansprüche sind in Fällen leichter
              Fahrlässigkeit ausgeschlossen. Das Vorliegen von leichter bzw.
              grober Fahrlässigkeit hat der/die Geschädigte zu beweisen.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>14.2</ListTextNumber>
            <ListText>
              Schadenersatzansprüche verjähren zwei Jahre ab Beendigung unserer
              Tätigkeit, spätestens jedoch binnen zwei Jahren ab Legung der
              Schlusshonorarnote, sofern das Gesetz keine kürzere
              Verjährungsfrist vorsieht.
              <br />
              Die in diesen AGB-ZT enthaltenen oder sonst vereinbarten
              Bestimmungen über Schadenersatz gelten auch dann, wenn der
              Schadenersatzanspruch neben oder anstelle eines
              Gewährleistungsanspruches geltend gemacht wird.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>14.3</ListTextNumber>
            <ListText>
              Unsere Pläne und sonstigen Unterlagen dürfen bei sonstigem
              Ausschluss von Schadenersatzansprüchen nur nach allenfalls
              erforderlicher behördlicher Genehmigung und ausdrücklicher
              Freigabe durch uns zur Ausführung verwendet werden.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>14.4</ListTextNumber>
            <ListText>
              Betreffend Pkt 14.1.) sowie 14.2.) erster Satz gelten für Verträge
              mit VerbraucherInnen im Sinne des Konsumentenschutzgesetzes die
              dort festgelegten Regelungen.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 15. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>15.</ListHeaderNumber>
            <ListHeaderText>Schadenersatz</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>15.1</ListTextNumber>
            <ListText>
              Es gilt österreichisches Recht. Die Anwendbarkeit des
              UN-Kaufrechtes wird ausdrücklich ausgeschlossen. Die
              Vertragssprache ist deutsch. Die Vertragsparteien vereinbaren
              österreichische, inländische Gerichtsbarkeit. Zur Entscheidung
              aller aus diesem Vertrag entstehenden Streitigkeiten ist das an
              unserem Kanzleisitz sachlich zuständige Gericht ausschließlich
              örtlich zuständig. Pkt 15 letzter Satz gilt nicht bei
              Verbrauchergeschäften.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 16. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>16.</ListHeaderNumber>
            <ListHeaderText>Erfüllungsort</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>16.1</ListTextNumber>
            <ListText>Erfüllungsort ist unser Kanzleisitz</ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 17. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>17.</ListHeaderNumber>
            <ListHeaderText>Adressänderung</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>17.1</ListTextNumber>
            <ListText>
              Der Vertragspartner/die Vertragspartnerin ist verpflichtet, uns
              Änderungen seiner/ihrer Wohn- bzw Geschäftsadresse bekanntzugeben,
              solange das vertragsgegenständliche Rechtsgeschäft nicht
              beiderseitig vollständig erfüllt ist. Wird die Mitteilung
              unterlassen, so gelten Erklärungen auch dann als zugegangen, falls
              sie an die zuletzt bekanntgegebene Adresse gesendet werden.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>

        {/* 18. */}
        <ListItemContainer>
          <ListHeaderContainer>
            <ListHeaderNumber>18.</ListHeaderNumber>
            <ListHeaderText>Salvatorische Klausel</ListHeaderText>
          </ListHeaderContainer>

          <ListTextContainer>
            <ListTextNumber>18.1</ListTextNumber>
            <ListText>
              Sollten einzelne Bestimmungen dieser AGB-ZT ganz oder teilweise
              unwirksam oder undurchführbar sein oder werden, so berührt dies
              die Gültigkeit der Bedingungen im Übrigen nicht. Im Falle der
              Unwirksamkeit einer einzelnen Bestimmung ist diese durch eine
              wirksame, die dem angestrebten Ziel möglichst nahe kommt, zu
              ersetzen.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>18.2</ListTextNumber>
            <ListText>
              Änderungen und Ergänzungen bedürfen zu ihrer Wirksamkeit der
              Schriftform, dies gilt insbesondere auch für das Abgehen von
              diesem Formerfordernis.
            </ListText>
          </ListTextContainer>

          <ListTextContainer>
            <ListTextNumber>18.3</ListTextNumber>
            <ListText>
              Sollten einzelne Bestimmungen dieser AGB-ZT ganz oder teilweise
              unwirksam oder undurchführbar sein oder werden, so berührt dies
              die Gültigkeit der Bedingungen im Übrigen nicht. Im Falle der
              Unwirksamkeit einer einzelnen Bestimmung ist diese durch eine
              wirksame, die dem angestrebten Ziel möglichst nahe kommt, zu
              ersetzen.
            </ListText>
          </ListTextContainer>
        </ListItemContainer>
      </TextSubContainer>
    </Container>
  );
};

export default AGB;
