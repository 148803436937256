import styled from "styled-components"

import image from '../assets/services.png'

const Container = styled.div`
  position: relative;
  border: solid .0625rem var(--color-border);
`;

const BackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 43.75rem) {
    visibility: hidden;
  }

`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 6rem 0 6rem;

  @media screen and (max-width: 62.5rem) {
    margin: 0 1rem 0 1rem ;
    /* background: #f5f4f4; */
  }

`;

const Group = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
`;

const SectionMark = styled.div`
  height: .0625rem;
  width: 80%;
  background-color: var(--color-border);
`;

const Header = styled.h1`
  margin: 2rem 0 2rem 0;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: .125rem;
`;
const SubHeader = styled.h3`
  margin: 0 0 .625rem 0 ;
  text-align: center;
`;

const Text = styled.p`
  text-align: center;
  margin: 0 0 .625rem 0 ;

  &:last-of-type{
    margin-bottom: 3rem;
  }

`;

const Services = () => {
  return (
    <Container id="services">
      <BackgroundImage src={image} />
      <TextContainer>
        <Header id="header">Leistungen</Header>
        <Group>
          <SubHeader>Beratung in den Themengebieten Anlagen- und Produktionseffizienz</SubHeader>
          <Text>
            Mit jahrelanger Erfahrung in der Produktionstechnik unterstütze ich Sie in allen Anliegen.
            Beispiele sind die Unterstützung im Beschaffungsprozess von Neuanlagen, die Erstellung von kompletten Anlagenkonzepten
            oder die Effizienzsteigerung bei bestehenden Anlagen und Produktionsabläufen.
          </Text>
          <SectionMark />
        </Group>
        <Group>
          <SubHeader>Sicherheitstechnische Betriebsbetreuung sowie Abnahmen und wiederkehrende Prüfungen nach AM-VO</SubHeader>
          <Text>
            Arbeitsmittel bedürfen vor der ersten Inbetriebnahme einer Abnahmeprüfung gemäß §7 AM-VO.
            Nach Inbetriebnahme sind diese einer wiederkehrenden Prüfung nach §8 AM-VO durch Ziviltechniker zu unterziehen.
          </Text>
          <SectionMark />
        </Group>
        <Group>
          <SubHeader>Überprüfungen und Beratung gemäß VbF 2023</SubHeader>
          <Text>
            Unterstützung im Genehmigungsverfahren von neuen Tankanlagen. Beratung bei bestehenden Lagern in Bezug auf die VbF-Novelle 2023.
            Der Ziviltechniker ist gemäß §27 VbF dazu befähigt, Erstprüfungen und wiederkehrende Prüfungen nach §22 - §28 VbF 2023 durchzuführen.
          </Text>
          <SectionMark />
        </Group>
        <Group>
          <SubHeader>Überprüfungen und Beratung gemäß VEXAT</SubHeader>
          <Text>
            Überprüfungen und Beratung gemäß VEXAT
            Erstellung von Gefahrenanalysen und Explosionsschutzdokumenten nach VEXAT.
            Der Ziviltechniker ist gemäß §7 VEXAT dazu befähigt, Erstprüfungen und wiederkehrende Prüfungen nach §7 VEXAT durchzuführen.
          </Text>
          <SectionMark />
        </Group>
        <Group>
          <SubHeader>Überprüfungen gemäß §82 GewO 1994</SubHeader>
          <Text>
            Der Ziviltechniker gilt als fachkundige Person im Sinne des §82b der Gewerbeordnung (GewO) und ist berechtigt,
            die vorgeschriebenen wiederkehrenden Überprüfungen durchzuführen.
          </Text>
          <SectionMark />
        </Group>
        <Group>
          <SubHeader>Berufsmäßige Vertretung gegenüber Behörden</SubHeader>
          <Text>
            Der Ziviltechniker ist gemäß ZTG 2019 zur berufsmäßigen Parteienvertretung vor Verwaltungsbehörden, Gerichten, usw. berechtigt.
            Ich unterstütze Sie bei den notwendigen Behördenwegen wie beispielsweise der Betriebsanlagengenehmigung.
          </Text>
        </Group>
      </TextContainer>
    </Container>
  )
}

export default Services
