import React from 'react'
import styled from 'styled-components'

import headerImg from '../assets/header.png';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinkContainer = styled.div`
  margin-bottom: 0.5rem;
  @media screen and (max-width: 62.5rem) {
    margin-bottom: 0.5rem;    
  }
`;

const Image = styled.img`
  margin-top: .3125rem;
  width: 100%;
`;

const Link = styled.a`
  color: var(--color-links);
  margin-right: 2rem;
  font-size: 1rem;
  font-weight: 600;

  &:last-of-type{
    margin-right: 0;
  }

  @media screen and (max-width: 62.5rem) {
    margin-right: 0.5rem;
    font-size: .75rem;
  }
`;

const SectionMark = styled.div`
  height: .0625rem;
  width: 100%;
  background-color: var(--color-border);
  margin-bottom: 0.5rem;
`;

const Header = () => {
  const location = useLocation();

  if (location.pathname === '/') {
    return (
      <Container>
        <Image src={headerImg} />
        <SectionMark />
        <LinkContainer>
          <Link href='#about'>Über uns</Link>
          <Link href='#services'>Leistungen</Link>
          <Link href='#description'>Info</Link>
          <Link href='#contact'>Kontakt</Link>
        </LinkContainer>
        <SectionMark />
      </Container>
    );
  } else {
    return (
      <Container style={{ alignItems: 'center' }}>
        <Image src={headerImg} />
        <SectionMark />
        <LinkContainer>
          <Link href='/'>Home</Link>
        </LinkContainer>
        <SectionMark />
      </Container>
    );
  }
}

export default Header