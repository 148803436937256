import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 0 0;
  border: solid 0.0625rem var(--color-border);
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 62.5rem) {
    flex-direction: column-reverse;
  }
`;

const Header = styled.h1`
  margin: 2rem 0 2rem 0;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.125rem;
`;

const AddressContainer = styled.div`
  margin: auto;

  @media screen and (max-width: 62.5rem) {
    margin-bottom: 3rem;
  }
`;

const Address = styled.h3`
  font-weight: 200;
`;

const Email = styled.a`
  color: var(--color-links);
  text-decoration: underline;
`;
const Map = styled.iframe`
  border: none;
  width: 30rem;
  height: 18.75rem;
  margin: auto;
  margin-bottom: 3rem;

  @media screen and (max-width: 62.5rem) {
    width: auto;
    height: auto;
  }
`;

const Contact = () => {
  return (
    <Container id="contact">
      <Header id="header">Kontakt</Header>
      <SubContainer>
        <AddressContainer>
          <Address>
            DI (FH) Anja Hajek <br />
            Schnaidt 42 <br />
            5242 Sankt Johann am Walde <br />
            Tel: +43 (0)660 200 9464
          </Address>
          <Address>
            <Email href="mailto:office@zt-hajek.at">office@zt-hajek.at</Email>
          </Address>
        </AddressContainer>
        <Map
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.9595496460825!2d13.324180077061635!3d48.130303451666585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47743d1098aaa97d%3A0xa91a5bde67fa6a0a!2sSchnaidt%2042%2C%205242%20St.%20Johann%20am%20Walde!5e0!3m2!1sde!2sat!4v1701779235753!5m2!1sde!2sat"
          allowFullScreen=""
          width="600"
          height="450"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </SubContainer>
    </Container>
  );
};

export default Contact;
