import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  max-width: 71.875rem;
  margin-top: 2rem;
`;

const TextSubContainer = styled.div`
  margin-top: 1rem;
  border: solid 0.0625rem var(--color-border);
`;

const TextContainer = styled.div`
  margin: 1rem;
`;
const Header = styled.h1`
  @media screen and (max-width: 26.875rem) {
    word-break: break-all;
  }
`;

const Text = styled.p`
  text-align: justify;
`;

const BoldText = styled.p`
  font-weight: bold;
  text-align: justify;
  margin-right: 0.5rem;
`;

const TextWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 50rem) {
    flex-direction: column;
    margin-top: 1rem;
  }
`;

const Email = styled.a`
  color: var(--color-links);
  text-decoration: underline;
`;

const Information = styled.p`
  align-self: flex-start;
  margin: 1rem 0 0 0;
  font-size: 0.75rem;
`;

const Link = styled.a`
  color: var(--color-links);
  text-decoration: underline;
`;

const UL = styled.ul`
  margin: 1rem 0 0 2rem;
`;
const LI = styled.li`
  margin-top: 0.5rem;
`;

const Imprint = () => {
  return (
    <Container>
      <Header id="header">Impressum</Header>
      <Information>
        Informationspflicht laut §5 E-Commerce Gesetz, §14
        Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut
        §25 Mediengesetz.
      </Information>
      <TextSubContainer>
        <TextContainer>
          <Text style={{ fontWeight: 'bold' }}>
            ZT Hajek – Zivilingenieure für Maschinenbau
          </Text>
          <Text>Dipl.-Ing. (FH) Anja Hajek</Text>
          <Text>Schnaidt 42</Text>
          <Text>5242 Sankt Johann am Walde</Text>
          <Text>Tel: +43 (0)660 200 9464</Text>
          <Email href="mailto:office@zt-hajek.at">office@zt-hajek.at</Email>
        </TextContainer>

        <TextContainer>
          <TextWrapper>
            <BoldText>Unternehmensgegenstand:</BoldText>
            <Text>Technische Dienstleistungen</Text>
          </TextWrapper>

          <TextWrapper>
            <BoldText>UID-Nr.:</BoldText>
            <Text>ATU80765646</Text>
          </TextWrapper>
        </TextContainer>
        <TextContainer>
          <Text>
            Mitglied der Kammer der ZiviltechnikerInnen für Oberösterreich und
            Salzburg
          </Text>
        </TextContainer>
        <TextContainer>
          <TextWrapper>
            <BoldText>Berufsrecht:</BoldText>
            <Text>
              Ziviltechnikergesetz (ZTG 2019): www.ris.bka.gv.at;{' '}
              <Link
                href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=20010625"
                target="_blank"
              >
                ZTG 2019
              </Link>
            </Text>
          </TextWrapper>
        </TextContainer>

        <TextContainer>
          <TextWrapper>
            <BoldText>Aufsichtsbehörde/Gewerbebehörde:</BoldText>
            <Text>
              Bundesministerium für Digitalisierung und Wirtschaftsstandort
              (BMDW)
            </Text>
          </TextWrapper>

          <TextWrapper>
            <BoldText>Berufsbezeichnung:</BoldText>
            <Text>Ziviltechniker</Text>
          </TextWrapper>

          <TextWrapper>
            <BoldText>Verleihungsstaat:</BoldText>
            <Text>Österreich</Text>
          </TextWrapper>
        </TextContainer>

        <TextContainer>
          <BoldText>EU-Streitschlichtung</BoldText>
          <Text>
            Gemäß Verordnung über Online-Streitbeilegung in
            Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
            Online-Streitbeilegungsplattform (OS-Plattform) informieren.
            Verbraucher haben die Möglichkeit, Beschwerden an die
            Online-Streitbeilegungsplattform der Europäischen Kommission unter{' '}
            <Link href="http://ec.europa.eu/odr?tid=121389755" target="_blank">
              http://ec.europa.eu/odr?tid=121389755
            </Link>{' '}
            zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb
            in unserem Impressum.
          </Text>
        </TextContainer>

        <TextContainer>
          <Text>
            Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
            verpflichtet sind, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.
          </Text>
        </TextContainer>

        <TextContainer>
          <BoldText>Haftung für Inhalte dieser Webseite</BoldText>
          <Text>
            Wir entwickeln die Inhalte dieser Webseite ständig weiter und
            bemühen uns korrekte und aktuelle Informationen bereitzustellen.
            Leider können wir keine Haftung für die Korrektheit aller Inhalte
            auf dieser Webseite übernehmen, speziell für jene die seitens
            Dritter bereitgestellt wurden. Sollten Ihnen problematische oder
            rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu
            kontaktieren, Sie finden die Kontaktdaten im Impressum.
          </Text>
        </TextContainer>

        <TextContainer>
          <BoldText>Haftung für Links auf dieser Webseite</BoldText>
          <Text>
            Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt
            wir nicht verantwortlich sind. Haftung für verlinkte Websites
            besteht laut{' '}
            <Link
              href="https://www.ris.bka.gv.at/Dokument.wxe?Abfrage=Bundesnormen&Dokumentnummer=NOR40025813&tid=121389755"
              target="_blank"
            >
              § 17 ECG
            </Link>{' '}
            für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten
            hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht
            aufgefallen sind und wir Links sofort entfernen würden, wenn uns
            Rechtswidrigkeiten bekannt werden.
          </Text>
        </TextContainer>

        <TextContainer>
          <Text>
            Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten
            wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im
            Impressum.
          </Text>
        </TextContainer>

        <TextContainer>
          <BoldText>Bildernachweis</BoldText>
          <Text>
            Die Bilder, Fotos und Grafiken auf dieser Webseite sind
            urheberrechtlich geschützt.
          </Text>
        </TextContainer>

        <TextContainer>
          <Text>Die Bilderrechte liegen bei den folgenden Unternehmen:</Text>
          <UL>
            <LI>Fotolounge Blende 8, Obernberg am Inn</LI>
            <LI>Freepik.com</LI>
            <LI>pixabay.com</LI>
          </UL>
        </TextContainer>
      </TextSubContainer>
    </Container>
  );
};

export default Imprint;
