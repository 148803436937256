import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Home from './pages/Home';
import Header from './components/Header';
import styled from 'styled-components';
import AGB from './pages/AGB';
import Footer from './components/Footer';
import Secure from './pages/Secure';
import Imprint from './pages/Imprint';

const Container = styled.div`
  margin: auto;
  max-width: 71.875rem;
  padding-left: 5rem;
  padding-right: 5rem;
  border-left: solid 0.0625rem var(--color-links);
  border-right: solid 0.0625rem var(--color-links);

  @media screen and (max-width: 62.5rem) {
    border: none;
    padding: 0.3125rem;
  }
`;

function App() {
  return (
    <Container>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AGB" element={<AGB />} />
          <Route path="/Secure" element={<Secure />} />
          <Route path="/Imprint" element={<Imprint />} />
        </Routes>
        <Footer />
      </Router>
    </Container>
  );
}

export default App;
