import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  max-width: 71.875rem;
  margin-top: 2rem;
`;

const TextSubContainer = styled.div`
  margin-top: 3rem;
  border: solid 0.0625rem var(--color-border);
`;

const TextContainer = styled.div`
  margin: 1rem;
`;
const Header = styled.h1`
  @media screen and (max-width: 26.875rem) {
    word-break: break-all;
  }
`;

const SubHeader = styled.h3`
  margin-bottom: 1rem;
`;
const Text = styled.p`
  text-align: justify;
`;

const Email = styled.a`
  color: var(--color-links);
  text-decoration: underline;
`;

const Secure = () => {
  return (
    <Container>
      <Header id="header">
        Datenschutzerklärung bzw. Informationen gemäß Art 13 und 14 DSGVO
      </Header>
      <TextSubContainer>
        <TextContainer>
          <SubHeader>Datenschutzrechtlich Verantwortlich:</SubHeader>
          <Text>Dipl.-Ing. (FH) Anja Hajek</Text>
          <Text>Schnaidt 42</Text>
          <Text>5242 Sankt Johann am Walde</Text>
          <Text>Tel: +43 (0)660 200 9464</Text>
          <Email href="mailto:office@zt-hajek.at">office@zt-hajek.at</Email>
        </TextContainer>
        <TextContainer>
          <SubHeader>Datenverarbeitungszwecke:</SubHeader>
          <Text>
            <Text style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
              Kontaktformular
            </Text>
            Wenn Sie per E-Mail Kontakt mit uns aufnehmen, werden Ihre
            angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall
            von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten
            geben wir nicht ohne Ihre Einwilligung weiter.
          </Text>
          <Text>
            <Text style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
              Informationen zu Cookies
            </Text>
            Diese Website verwendet keine Cookies.
          </Text>
        </TextContainer>
        <TextContainer>
          <SubHeader>Ihre Rechte</SubHeader>
          <Text>
            Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
            Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit,
            Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung
            Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
            datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt
            worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In
            Österreich ist dies die Datenschutzbehörde.
          </Text>
        </TextContainer>
      </TextSubContainer>
    </Container>
  );
};

export default Secure;
